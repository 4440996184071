.arrowRight {
    position: absolute;
    top: 50%;
    right: -20px;
    color: white;
    transform: translateX(-97%) translateY(-50%);
}

.arrowLeft {
    position: absolute;
    top: 50%;
    left: 10px;
    color: white;
    transform: translateX(-3%) translateY(-50%);
}

.arrowClose {
    position: absolute;
    top: -15px;
    right: -20px;
    color: white;
}

.image {
    height: auto;
    max-height: 90vh;
    width: auto;
    max-width: 90vh;
}

.imageGroup {
    margin: 0!important;
}

.scrollContainer {
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scrollContainer::-webkit-scrollbar {
    display: none;
}

.scrollContainer img {
    padding-top: 0.5em;
}