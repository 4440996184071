body {
    margin: 0;
    font-family: "Roboto","Open Sans", Arial, sans-serif;
    color: #333;
}

p {
    color: #333;
}

/*Colors Logo*/
:root {
    --blue: 69,147,198;
    --silver: 102,102,102;
    --yellow: 247,205,71;
}

/*Fonts*/
.fontH1 {
    font-size: 20px;
    color: #666;
    margin-bottom: 4px;
    margin-top: 4px;
}

.fontA1 {
    color: #666;
    font-weight: bolder;
}

.fontH3 {
    font-size: 1.4em;
    font-weight: bold;
    line-height: 1.5em;
    color: #666;
    padding-bottom: 0.3em;
}

.fontP {
    font-size: 1.2em;
    line-height: 1.5em;
    color: #333;
    text-align: justify;
}

.fontRequired {
    color: red;
    font-weight: bolder;
}

.fontRequired:before {
    content: "*";
    color: red;
    margin-right: 2px;
}

.fontFooter {
    color: #eee;
    border-top-color: #eee;
}
.fontFooter:any-link {
    color: #eee;
}

.fontFooterHorizontal {
    color: #eee;
}
.fontFooterHorizontal:any-link {
    color: #eee;
}
.fontFooterHorizontal:after {
    color: #eee;
    content: "|";
    margin-left: 1em;
}
.fontPrice {
    font-weight: bold;
}

.labelH3 {
    font-size: 1.28571429rem;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-weight: 700;
    padding-bottom: 0.5em;
    padding-top: 1em;
}

.labelH3 > .checkbox {
    font-size: 1.28571429rem;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-weight: 700;
    padding: 0;
}

.headingTitle {
    color: #0094c9;
    font: 2.8em "Roboto", "Open Sans", Arial, sans-serif;
    text-align: center;
    font-variant: small-caps;
}

.headingSubTitle {
    color: #666;
    font: 2.8em "Roboto", "Open Sans", Arial, sans-serif;
    text-align: center;
    font-variant: small-caps;
}

.headingTour {
    color: white;
    font: 3em "Roboto", "Open Sans", Arial, sans-serif;
    text-align: center;
    font-variant: small-caps;
    position: relative;
    background-color: rgba(102,102,102);
    padding: 0.5em 0.0em;
    margin-top: -5px!important;
}

.headingTourSmall {
    color: white;
    font: 2em "Roboto", "Open Sans", Arial, sans-serif;
    text-align: center;
    font-variant: small-caps;
    position: relative;
    background-color: rgba(102,102,102);
    padding: 0.2em 0.0em;
}


.advertisementTitle {
    color: #0094c9;
    font: 2.5em "Roboto", "Open Sans", Arial, sans-serif;
    font-variant: small-caps;
    margin-left: -7px;
}

.cardHeader {
    font: 1.7em "Roboto", "Open Sans", Arial, sans-serif;
    font-variant: small-caps;
    text-align: left;
    color: #eee;
}

.cardSubHeader {
    font: 1.1em "Roboto", "Open Sans", Arial, sans-serif;
    font-variant: small-caps;
    text-align: left;
    color: #eee;
}

.itemBlank {
    padding-left: 1.5em;
}

.errorInfo {
    color: red;
}
.errorInfo:after {
    content: "*";
    color: red;
    margin-right: 2px;
}

/*Padding*/
.pd1 {
    padding: 0.5em;
}

.pd2 {
    padding: 2em;
}

.pdR {
    padding-right: 0.5em;
}

/*Components*/
.container {
    background-color: white;
}

.containerMgmgt {
    width: 95%;
}

.breadcrumb-container {
    padding: 0.5em 0.5em;
    background-color: #f5f5f5;
    color: #666666;
}

.breadcrumb-container > .link {
    color: #666666;
}

.float-right {
    float: right;
}

/* Header image and overlay text*/
.logo {
    padding: 0.5em
}

.logoSx {
    padding: 0.1em 0.5em
}

.imageContainer {
    position: relative;
    text-align: center;
    color: white;
    font-size: 23px;
    font-weight: bolder;
}
.imageTitleBottomRight {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

.footer {
    background-color: #666666;
    color: #efefef;
    font-size: small;
    padding: 0.5em 5em;
    width: 100%;
}

.footer > .info {
    font-size: small;
    color: white;
    text-align: center;
    margin-bottom: 0.5em;
}

.footer > .link {
    font-size: small;
    color: white;
}

.footerImage {
    background-image: url("../public/assets/test.png");
    background-color: white;
    height: 50px;
    background-position: center;
    background-repeat: repeat-x;
}

.user-profile-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width:36px;
    height: 36px;
    background-color: gray;
    color: #ffffff;
    font-size: 20px;
    border-radius: 50%;
    font-weight: 800;
}

.tourHeadline {
    font-weight: bold;
    padding-bottom: 1em;
    padding-top: 1em;
}

.tourNote {
    padding-top: 1em;
}

.iconHeader {
    color: gray;
    font-size: 1.1em;
    cursor: pointer;
    padding: 1em 1em;
}

.fieldNoBorder {
    border-top: 0!important;
    border-right: 0!important;
    border-left: 0!important;
    border-radius: 0!important;
}

.fieldNoBorder > a, .ui.label{
    background-color: whitesmoke!important;
    border:none!important;
    box-shadow: none!important;
}
.fieldNoBorder >.ui.input>input{
    border-top: 0!important;
    border-right: 0!important;
    border-left: 0!important;
    border-radius: 0!important;
    box-shadow: none!important;
}
.fieldNoBorderList >.ui.input:before {
    margin-top: 9px;
    content:"• ";
}

.fieldDescription {
    color: #666;
    font-size: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
}

.confirmMessageText {
    padding: 1em 1em;
    line-height: 1.3em;
    font-size: 1.1em;
    display: block;
}

.dropzoneOverflow {
    color: white;
    font-size: 0.9em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

/* 16:9*/
.dropzoneHeader {
    width: auto;
    height: 170px
}

.dropzoneCard {
    width: 235px;
    height: auto;
}

.dropzoneRemove {
    padding: 0.3em 0.6em;
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
}

/* Table */
.tableHeader {
    background-color: aliceblue;
    font-weight: 500;
    font-size: 1.1em;
}

.tableOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.actionFooter {
    padding-top: 1em;
}

.bgLightBlue {
    background-color: #2bbbff;
}

.loading {
    filter: blur(10px);
    clip-path: inset(0);
}

.loaded {
    filter: blur(0px);
    transition: filter 0.5s linear;
}

/* Breakpoints settings*/
.headerImage {
    color: whitesmoke;
    font-weight: 500;
    font-variant: small-caps;
    background-color: transparent;
    word-break: break-word;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .headerImage {
        font-size: 1em;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .headerImage {
        font-size: 1.5em;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .headerImage {
        font-size: 2em;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .headerImage {
        font-size: 3em;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .header {
        font-size: 4em;
    }
}